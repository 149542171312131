/* src/components/pages/WelcomePage/WelcomePage.js */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomePage.css';

const WelcomePage = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');  // Redirige vers la page de connexion
  };
  return (
    <div className="welcome-wrapper">
      <h1 className="title">Welcome on Hive Team Flow Application</h1>
      <button className="button" onClick={goToLogin}>Se connecter</button>
      <a href="https://www.hiveteamflow.com" className="link-return-home">Visit our Website</a>
    </div>
  );
};
export default WelcomePage;
