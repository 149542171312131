/* src/components/pages/ForgotPasswordPage/ForgotPasswordPage.js */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../services/FirebaseConfig';
import CloseButton from '../../ui/buttons/CloseButton';
import LoginButton from '../../ui/buttons/LoginButton';
import UserDataInput from '../../ui/inputs/UserDataInput';
import './ForgotPasswordPage.css';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const goToWelcomePage = () => {
    navigate('/'); // Redirect to the welcome page
  };

    const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      // Envoie d'un email de réinitialisation de mot de passe
      await sendPasswordResetEmail(auth, email);
      setMessage('Un lien de réinitialisation a été envoyé à votre email.');
      setError('');
    } catch (err) {
      setError('Erreur : ' + err.message);
      setMessage('');
    }
  };

  return (
    <div className="forgotPassword-wrapper">
      <form className="forgotPassword-form" onSubmit={handleForgotPassword}>
        <CloseButton onClose={goToWelcomePage}/>
        <h2>Password forgotten ?</h2>
        {error && <p className="error-message">{error}</p>}        
        <UserDataInput
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
          className="UserDataInput"
        />
        <LoginButton type="submit" label="Send email to change password" />
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
    </div>
  );
};
export default ForgotPasswordPage;