import React, { useState, useEffect } from "react";
import "./EmployeeForm.css";

const EmployeeForm = ({
  addEmployee,
  updateEmployee,
  deleteEmployee,
  employees,
  selectedEmployeeId,
  selectedEmployee
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [service, setService] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [parentId, setParentId] = useState("");
  const [stepParentId, setStepParentId] = useState("");

  // Pré-remplir les champs lors de la sélection d'un employé
  useEffect(() => {
    if (selectedEmployee) {
      setFirstName(selectedEmployee.firstName);
      setLastName(selectedEmployee.lastName);
      setEmail(selectedEmployee.email);
      setDepartment(selectedEmployee.department);
      setService(selectedEmployee.service);
      setJobTitle(selectedEmployee.jobTitle);
      setLocation(selectedEmployee.location);
      setParentId(selectedEmployee.parentId || "");
      setStepParentId(selectedEmployee.stepParentId || "");
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setDepartment("");
      setService("");
      setJobTitle("");
      setLocation("");
      setParentId("");
      setStepParentId("");
    }
  }, [selectedEmployee]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newEmployee = {
      firstName,
      lastName,
      email,
      department: department.trim() || "Not Affected",
      service: service.trim() || "Not Affected",
      jobTitle,
      location: location.trim() || "Not Affected",
      parentId,
      stepParentId
    };

    addEmployee(newEmployee);
    resetForm();
  };

  const handleUpdate = () => {
    if (!selectedEmployeeId) return;

    const updatedEmployee = {
      id: selectedEmployeeId,
      firstName,
      lastName,
      email,
      department,
      service,
      jobTitle,
      location,
      parentId,
      stepParentId
    };

    updateEmployee(updatedEmployee);
    resetForm();
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setDepartment("");
    setService("");
    setJobTitle("");
    setLocation("");
    setParentId("");
    setStepParentId("");
  };

  return (
    <form onSubmit={handleSubmit} className="employee-form">
      <h2>Gérer les employés</h2>
      <div className="form-group">
        <label>Prénom</label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Entrez le prénom"
          required
        />
      </div>
      <div className="form-group">
        <label>Nom</label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Entrez le nom"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Entrez le mail"
          required
        />
      </div>
      <div className="form-group">
        <label>Département</label>
        <input
          type="text"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          placeholder="Entrez le département"
        />
      </div>
      <div className="form-group">
        <label>Service</label>
        <input
          type="text"
          value={service}
          onChange={(e) => setService(e.target.value)}
          placeholder="Entrez le service"
        />
      </div>
      <div className="form-group">
        <label>Poste</label>
        <input
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          placeholder="Entrez le poste"
          required
        />
      </div>
      <div className="form-group">
        <label>Localisation</label>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Entrez la localisation"
        />
      </div>
      <div className="form-group">
        <label>Rapporte à</label>
        <select value={parentId} onChange={(e) => setParentId(e.target.value)}>
          <option value="">Aucune personne</option>
          {employees.map((emp) => (
            <option key={emp.id} value={emp.id}>
              {emp.firstName} {emp.lastName}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Géré aussi par</label>
        <select value={stepParentId} onChange={(e) => setStepParentId(e.target.value)}>
          <option value="">Aucune personne</option>
          {employees.map((emp) => (
            <option key={emp.id} value={emp.id}>
              {emp.firstName} {emp.lastName}
            </option>
          ))}
        </select>
      </div>
      <div className="form-actions">
        <button 
          type="submit" 
          className="add-button" 
          onClick={handleSubmit}
          disabled={!!selectedEmployeeId}
        >
          Ajouter
        </button>
        <button
          type="button"
          className="update-button"
          onClick={handleUpdate}
          disabled={!selectedEmployeeId}
        >
          Modifier
        </button>
        <button
          type="button"
          className="delete-button"
          onClick={deleteEmployee}
          disabled={!selectedEmployeeId}
        >
          Supprimer
        </button>
      </div>
    </form>
  );
};

export default EmployeeForm;
