// src/services/FirebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {  
  apiKey: "AIzaSyBn24QLe9mzr_XY9qb7yls2vimNaWovCf0",
  authDomain: "orgchartviewer-29001.firebaseapp.com",
  projectId: "orgchartviewer-29001",
  storageBucket: "orgchartviewer-29001.firebasestorage.app",
  messagingSenderId: "227946147399",
  appId: "1:227946147399:web:b21293323e628b9d5e8b62"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// Export Firebase services
export const auth = getAuth(app);
export { db };
export const storage = getStorage(app);

