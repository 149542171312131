import React from "react";
import { Handle } from "reactflow";

const HexagonalNode = ({ data, isSelected, setIsSelected }) => {
  const hexagonPath =
    "M 50,0 " +
    "L 100,25 " +
    "L 100,75 " +
    "L 50,100 " +
    "L 0,75 " +
    "L 0,25 " +
    "Z";

  // Gestion du clic
  const handleClick = (event) => {
    event.stopPropagation(); // Empêche la propagation vers ReactFlow
    setIsSelected(!isSelected); // Basculer la sélection
  };

  return (
    <div
      onClick={handleClick} // Gestion du clic pour basculer la sélection
      style={{
        width: "100px",
        height: "100px",
        position: "relative",
        cursor: "pointer",
        backgroundColor: isSelected ? "#e6f7ff" : "#fff",
        clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
        WebkitClipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
      }}
    >
      <svg width="100" height="100" style={{ display: "block" }}>
        <path
          d={hexagonPath}
          fill={isSelected ? "#f0f8ff" : "#fff"} // Couleur de fond selon sélection
          stroke={isSelected ? "#007bff" : "#ddd"} // Couleur de la bordure selon sélection
          strokeWidth="2"
        />
        <foreignObject x="10" y="10" width="80" height="80">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                color: isSelected ? "#007bff" : "#333",
              }}
            >
              <strong>{data.label}</strong>
            </div>
          </div>
        </foreignObject>
      </svg>
      <Handle
        type="source"
        position="bottom"
        style={{ background: "#555"}}
      />
      <Handle
        type="target"
        position="top"
        style={{ background: "#555" }}
      />
    </div>
  );
};

export default HexagonalNode;
