import React, { useState } from 'react';
import Header from "../../layouts/Header";
import './ContactPage.css';

const ContactPage  = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatus('Votre message a été envoyé avec succès !');
    setFormData({
      email: '',
      message: '',
    });
  };

  return (
    <div className="contact-page">
      <Header />
      <div className="contact-page-intern">
      <h1>Contactez-nous</h1>
      <p>Si vous avez des questions ou des suggestions, n'hésitez pas à nous écrire.</p>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Envoyer</button>
      </form>
      {status && <p className="status-message">{status}</p>}
          </div>
    </div>
  );
}

export default ContactPage;