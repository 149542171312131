/* src/components/layout/Header/Header.js */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import logo from '../../../logo.svg'; // Import du logo
import UserInfo from '../UserInfo';
import profilePhoto from "../../../assets/images/profilepicture.webp"; // Import de l'image .webp
import './Header.css';

function Header() {
  const { user } = useAuth();

  const [isUserInfoOpen, setUserInfoOpen] = useState(false);

  const toggleUserInfo = () => {
    setUserInfoOpen(!isUserInfoOpen);
  };

  return (
    <header className="header">
      {user ? (
        <div className="header-content">
          <img src={logo} alt="Logo" className="logo" />
          <nav className="nav">
            <Link to="/orgchart" className="nav-link">Home</Link>
            <Link to="/about" className="nav-link">About</Link>
            <Link to="/contact" className="nav-link">Contact</Link>
          </nav>
          <img src={profilePhoto} alt="Profile" className="profile-photo" onClick={toggleUserInfo} />
          {isUserInfoOpen && <UserInfo />}
         
        </div>
      ) : (
        <span className="not-logged-in">Vous n'êtes pas connecté</span>
      )}
    </header>
  );
}

export default Header;

/*
 {isMenuOpen && <SidebarMenu />}
*/
