import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SubscriptionPage, LoginPage, OrgChartPage, WelcomePage, ContactPage, AboutPage, ForgotPasswordPage } from "./components/pages";
import { AuthProvider } from './contexts/AuthContext';
import "./App.css";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/subscribe" element={<SubscriptionPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/orgchart" element={
            <AuthProvider>
              <OrgChartPage />
            </AuthProvider>
          } />
          <Route path="/contact" element={
            <AuthProvider>
              <ContactPage />
            </AuthProvider>
            
          } />
          <Route path="/about" element={
            <AuthProvider>
              <AboutPage />
            </AuthProvider>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
