/* src/components/pages/LoginPage/LoginPage.js */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../../services/FirebaseConfig';
import CloseButton from '../../ui/buttons/CloseButton';
import LoginButton from '../../ui/buttons/LoginButton';
import UserDataInput from '../../ui/inputs/UserDataInput';
import { FaMicrosoft } from "react-icons/fa6";
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const goToWelcomePage = () => {
    navigate('/'); // Redirect to the welcome page
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/orgchart'); // Redirect to the main page after login
    } catch (err) {
      setError("Erreur d'authentification : " + err.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/orgchart'); // Redirect to the main page after login
    } catch (err) {
      setError("Erreur d'authentification : " + err.message);
    }
  };

  return (
    <div className="login-wrapper">
      <form className="login-form" onSubmit={handleLogin}>
        <CloseButton onClose={goToWelcomePage}/>
        <h2>Connexion</h2>
        {error && <p className="error-message">{error}</p>}
        <UserDataInput
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(value) => setPassword(value)}
          required
          className="UserDataInput"
        />
        <LoginButton type="submit" label="Se connecter" />
        <LoginButton type="submit" label="Se connecter avec Microsoft" onClick={handleGoogleLogin}>
          <FaMicrosoft size={40} color="blue" />
        </LoginButton>
      </form>
      <div className="link-container">
        <Link to="/subscribe">Create a free trial account</Link>
        <Link to="/forgotpassword">Forgot password ?</Link>
      </div>
    </div>
  );
};
export default LoginPage;