import React, { useRef, useEffect, useState } from "react";
import ReactFlow, { Controls } from "reactflow";
import HexagonalNode from "../HexagonalNode";
import "reactflow/dist/style.css";

const OrgChart = ({ employees, selectedEmployeeId, setSelectedEmployeeId, companyId }) => {
  const reactFlowWrapper = useRef(null);
  const reactFlowInstance = useRef(null);

  const [selectedNode, setSelectedNode] = useState(null);

  // Filtrer les employés par ID de l'entreprise
  const filteredEmployees = employees.filter((emp) => emp.companyId === companyId);

  const calculatePositions = (employees) => {
    const positions = {};
    const levelSpacing = 120;
    const siblingSpacing = 275;

    const getEmployeeLevels = () => {
      const levels = {};
      employees.forEach((emp) => {
        if (!emp.parentId) levels[emp.id] = 0;
      });

      const maxLevels = employees.length;
      for (let parentLevel = 0; parentLevel < maxLevels; parentLevel++) {
        employees.forEach((emp) => {
          if (levels[emp.parentId] === parentLevel) levels[emp.id] = parentLevel + 1;
        });
      }

      return levels;
    };

    const levels = getEmployeeLevels();

    employees
      .sort((a, b) => levels[a.id] - levels[b.id])
      .forEach((emp) => {
        const level = levels[emp.id];
        const parentPosition = positions[emp.parentId];
        const parentX = parentPosition ? parentPosition.x : 0;

        const siblings = employees.filter((e) => e.parentId === emp.parentId);
        const totalWidth = (siblings.length - 1) * siblingSpacing;
        const baseX = parentX - totalWidth / 2;

        siblings.forEach((sibling, siblingIndex) => {
          if (!positions[sibling.id]) {
            positions[sibling.id] = {
              x: baseX + siblingIndex * siblingSpacing,
              y: level * levelSpacing,
            };
          }
        });
      });

    return positions;
  };

  const positions = calculatePositions(filteredEmployees);

  const nodes = filteredEmployees.map((emp) => ({
    id: emp.id,
    position: positions[emp.id],
    type: "hexagonal",
    data: {
      label: (
        <div style={{ textAlign: "center" }}>
          <div>
            <strong>{emp.firstName}</strong> <br />
            <small>{emp.lastName}</small>
          </div>
        </div>
      ),
    },
    sourcePosition: "bottom",
    targetPosition: "top",
  }));

  const edges = filteredEmployees
    .filter((emp) => emp.parentId)
    .map((emp) => ({
      id: `e${emp.parentId}-${emp.id}`,
      source: emp.parentId,
      target: emp.id,
      animated: true,
      style: { stroke: "black" ,strokeWidth: 1},
    }));

  const nodeTypes = {
    hexagonal: (props) => (
      <HexagonalNode
        {...props}
        isSelected={selectedNode?.id === props.id}
        setIsSelected={(isSelected) => {
          if (isSelected) {
            setSelectedNode(props);
            setSelectedEmployeeId(props.id);
          } else {
            setSelectedNode(null);
            setSelectedEmployeeId(null);
          }
        }}
      />
    ),
  };

  useEffect(() => {
    if (reactFlowInstance.current) {
      reactFlowInstance.current.fitView({ duration: 800, padding: 0.1 });
    }
  }, [nodes, edges]);

  return (
    <div 
      ref={reactFlowWrapper} 
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        onInit={(instance) => (reactFlowInstance.current = instance)}
        panOnScroll={false}
        zoomOnScroll={true}
        minZoom={0.2}
        maxZoom={3}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};

export default OrgChart;
