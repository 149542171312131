import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes } from "firebase/storage";
import { auth, storage } from '../../../services/FirebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import SaveUserData from "../../../hooks/SaveUserData";
import CloseButton from '../../ui/buttons/CloseButton';
import LoginButton from '../../ui/buttons/LoginButton';
import UserDataInput from '../../ui/inputs/UserDataInput';
import './SubscriptionPage.css';

const db = getFirestore();

function SubscriptionPage() {
  const navigate = useNavigate();
  const goToWelcomePage = () => {
    navigate('/');
  };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [companyId, setCompanyId] = useState('');
  const [error, setError] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(''); // Nouveau state pour afficher les messages de chargement

  const handleSubscription = async (e) => {
    e.preventDefault();

    // Vérification des mots de passe
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    const TIMEOUT_DURATION = 10000; // Timeout pour chaque étape asynchrone (10 secondes)

    // Fonction utilitaire pour gérer les délais avec un timeout
    const withTimeout = (promise, timeoutMessage) => {
      return Promise.race([
        promise,
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error(timeoutMessage)), TIMEOUT_DURATION)
        ),
      ]);
    };

    try {
      // Étape 1 : Créer un utilisateur dans Firebase Auth
      setLoadingMessage("Création de l'utilisateur en cours..."); // Message de chargement
      const userCredential = await withTimeout(
        createUserWithEmailAndPassword(auth, email, password),
        "Le délai pour créer l'utilisateur est dépassé."
      );
      const user = userCredential.user;

      // Étape 2 : Ajouter une image de profil si fournie
      if (profilePicture) {
        try {
          setLoadingMessage("Téléchargement de l'image de profil en cours..."); // Message de chargement
          const photoRef = ref(storage, `profilePhotos/${user.uid}`);
          await withTimeout(
            uploadBytes(photoRef, profilePicture),
            "Le délai pour télécharger l'image de profil est dépassé."
          );
        } catch (error) {
          console.error("Erreur lors du téléchargement de l'image de profil :", error.message);
          // L'erreur est ignorée, le processus continue
        }
      }

      // Étape 3 : Sauvegarder les données utilisateur dans Firestore
      setLoadingMessage("Sauvegarde des données utilisateur en cours..."); // Message de chargement
      await withTimeout(
        SaveUserData(user.uid, { firstName, lastName, email, companyId }),
        "Le délai pour sauvegarder les données utilisateur est dépassé."
      );

      // Étape 4 : Vérifier si l'e-mail existe dans la collection `employees`
      setLoadingMessage("Vérification des employés en cours..."); // Message de chargement
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('companyId', '==', companyId));
      const querySnapshot = await withTimeout(
        getDocs(q),
        "Le délai pour vérifier les employés est dépassé."
      );

      // Étape 5 : Ajouter un employé si aucun n'existe
      if (querySnapshot.empty) {
        setLoadingMessage("Ajout d'un nouvel employé en cours..."); // Message de chargement
        await withTimeout(
          addDoc(employeesRef, {
            firstName,
            lastName,
            email,
            companyId,
            department: '',
            service: '',
            jobTitle: '',
            location: '',
            parentId: '',
            stepParentId: ''
          }),
          "Le délai pour ajouter un employé est dépassé."
        );
      }

      // old Étape 6 : Redirection après l'inscription
      //navigate('/login');
      // Etape 6 : Connexion puis redirection vers orgchartPage
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/orgchart');
    } catch (err) {
      console.error(err);
      setError("Erreur d'inscription : " + err.message);
    } finally {
      setLoadingMessage(''); // Réinitialiser le message de chargement à la fin du processus
    }
  };

  return (
    <div className="subscription-wrapper">
      <form className="subscription-form" onSubmit={handleSubscription}>
        <CloseButton onClose={goToWelcomePage} />
        <h2>Inscription</h2>
        {error && <p className="error-message">{error}</p>}
        {loadingMessage && <p className="loading-message">{loadingMessage}</p>} {/* Afficher le message de chargement */}
        <UserDataInput
          type="text"
          label="First Name"
          placeholder="First Name"
          value={firstName}
          onChange={(value) => setFirstName(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="text"
          label="Company ID"
          placeholder="Company ID"
          value={companyId}
          onChange={(value) => setCompanyId(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(value) => setPassword(value)}
          required
          className="UserDataInput"
        />
        <UserDataInput
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
          required
          className="UserDataInput"
        />
        <LoginButton type="submit" label="S'inscrire" />
      </form>
    </div>
  );
}

export default SubscriptionPage;
