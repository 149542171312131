import React, { useState } from 'react';
import styles from './UserDataInput.module.css';

const UserDataInput = ({
  type = '',       // Type de l'input (texte, email, mot de passe, fichier, etc.)
  label = '',          // Label pour l'input
  placeholder = '',    // Placeholder du champ
  value = '',          // Valeur initiale
  onChange = () => {}, // Fonction pour gérer les changements de valeur
  required = false,    // Si le champ est requis ou non
  className = '',
  maxLength = null,    // Longueur maximale
  minLength = null,    // Longueur minimale
  errorMessage = '',   // Message d'erreur en cas de validation
  validate = '',     // Fonction de validation personnalisée
  accept = null        // Acceptation des types MIME pour les fichiers
}) => {
  const [error, setError] = useState('');
  const [preview, setPreview] = useState(null); // Pour prévisualiser les images si nécessaire

  const handleChange = (event) => {
    let newValue;

    // Si le champ est un fichier
    if (type === 'file') {
      const file = event.target.files[0];
      if (file) {
        newValue = file;
        setPreview(URL.createObjectURL(file)); // Prévisualisation pour les images
      }
    } else {
      newValue = event.target.value;
    }

    // Validation
    if (validate) {
      const validationError = validate(newValue);
      setError(validationError ? errorMessage : '');
    }

    // Transmettre la valeur au parent
    onChange(newValue);
  };

  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={type === 'file' ? undefined : value} // Les fichiers n'ont pas de propriété "value"
        onChange={handleChange}
        required={required}
        className={styles.UserDataInput}
        maxLength={maxLength}
        minLength={minLength}
        accept={accept}
        aria-invalid={error ? "true" : "false"}
      />
      {error && <span className="error-message">{error}</span>}
      {preview && type === 'file' && (
        <img
          src={preview}
          alt="Preview"
          style={{ width: "100px", height: "100px", marginTop: "10px" }}
        />
      )}
    </div>
  );
};

export default UserDataInput;
