import React from 'react';
import Header from "../../layouts/Header";
import './AboutPage.css';

const AboutPage  = () => {
  return (
    <div className="about-page">
      <Header />
      <div className="about-page-intern">
        <h1>À propos de nous</h1>
        <p>
          Bienvenue sur notre site ! Nous sommes une organisation dédiée à simplifier la gestion des organigrammes et à améliorer la collaboration au sein des entreprises.
        </p>
        <p>
        Notre mission est de fournir des solutions intuitives et puissantes pour gérer et visualiser les structures organisationnelles, tout en assurant la communication et la transparence.
        </p>
        <h2>Notre vision</h2>
        <p>
          Nous croyons qu'une organisation bien structurée et transparente est la clé de la réussite. Avec nos outils, nous aidons les entreprises à atteindre cet objectif.
        </p>
        <h2>Pourquoi nous choisir ?</h2>
        <ul>
          <li>Interface simple et intuitive</li>
          <li>Notifications en temps réel</li>
          <li>Personnalisation complète de vos organigrammes</li>
          <li>Sécurité et confidentialité de vos données</li>
        </ul>
        <p>
          Nous sommes passionnés par ce que nous faisons et nous nous efforçons d'améliorer constamment nos services pour répondre aux besoins de nos utilisateurs.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
