import { db } from "../services/FirebaseConfig";
import { doc, setDoc } from "firebase/firestore";

async function SaveUserData(userId, data) {
    try {
        // Utilisez `doc` pour spécifier la collection et l'ID du document
        const userRef = doc(db, "users", userId);
        // Utilisez `setDoc` pour ajouter ou remplacer le document
        await setDoc(userRef, data);
        console.log("Document ajouté ou mis à jour avec succès.");
    } catch (error) {
        console.error("Erreur lors de l'ajout ou de la mise à jour du document :", error);
    }
}
export default SaveUserData;